/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap'
@import './assets/css/swiper.min.css'
@import './assets/css/animate.min.css'

html, body
    font-family: 'Open Sans', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    -webkit-tap-highlight-color: rgba(0,0,0,0)

h1
    font-size: 42px
    font-weight: 700
    line-height: 52px
    color: #257DE6
    @media (max-width: 768px)
        font-size: 22px
        line-height: 32px

h2
    font-size: 42px
    font-weight: 700
    line-height: 52px
    color: #257DE6
    @media (max-width: 768px)
        font-size: 22px
        line-height: 32px

h3
    font-size: 32px
    font-weight: 700
    line-height: 42px
    color: #257DE6

    @media (max-width: 768px)
        font-size: 20px
        line-height: 30px

.blue-btn
    color: #fff !important
    font-size: 20px
    line-height: 40px
    padding: 0px 25px !important
    background: #257DE6
    border-radius: 10px
    display: inline-block
    box-shadow: 0px 4px 20px rgba(37, 125, 230, 0.5)

.highlight
    color: #ffa15a

.card
    border-radius: 29px
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)

    &-header
        border: none
        background-color: #ffffff

        button
            width: 100%
            outline: 0px
            padding: 5px 45px 5px 10px
            color: #0071da !important
            font-weight: 600
            font-size: 20px
            line-height: 27px
            text-align: left
            position: relative
            box-shadow: none !important
            text-decoration: none !important

            &::after
                content: "+"
                position: absolute
                right: 10px
                top: 5px
                font-weight: 800

            &[aria-expanded="true"]::after
                content: "—"
                top: 2px
                font-size: 0.8em

            @media (max-width: 768px)
                font-size: 18px

    &-body
        font-size: 16px
        line-height: 21px
        text-align: justify
        padding: 0px 60px 30px 30px

@media (max-width: 768px)
    .container
        padding-left: 30px
        padding-right: 30px
